<script setup lang="ts">
import { useLayout } from '@/shared/composables/layout/layout.use'
import { useModal } from '@/shared/composables/modal/modal.use'
import { cookiesAccess } from '@/shared/lib/cookiesAccess'
import IconMiliLogo from '@/shared/public/IconMiliLogo.svg'

import { FooterWithLinks } from '@/widgets/footer'
import { CookiesAccessModal } from '@/widgets/modal/cookies-access/modal'
import { CookiesAccessTopbar } from '@/widgets/modal/cookies-access/toolbar'
import { Preloader } from '@/widgets/preloader'

const { isDesktop } = useLayout()
const $modal = useModal()

onMounted(async () => {
  await nextTick()
  if (cookiesAccess.needShow) $modal?.open('cookies-access-tollbar')
})
</script>

<template>
  <div>
    <Preloader />
    <client-only>
      <div class="lg:bg-cabinet h-100-cvh flex flex-col">
        <div class="mb-16 flex h-80 w-full items-center justify-center lg:mb-8 lg:h-60">
          <IconMiliLogo class="my-12" />
        </div>

        <div
          class="lg:rounded-16 lg:min-w-960 lg:max-w-960 lg:min-h-664 lg:max-h-664 lg:container-shadow mx-auto flex w-full flex-grow flex-col  lg:bg-white"
        >
          <div
            class="lg:max-w-328 mx-auto flex w-full flex-grow flex-col px-24 pb-16 lg:px-0 lg:pt-80"
          >
            <slot />
          </div>
        </div>

        <FooterWithLinks v-if="isDesktop" class="mt-72 hidden lg:block" :show-title="true" />
        <CookiesAccessTopbar />
        <CookiesAccessModal />
      </div>
    </client-only>
  </div>
</template>

<style scoped>
.container-shadow {
  box-shadow: 0 8px 24px 0 rgba(227, 227, 227, 0.4);
}
</style>
